exports.components = {
  "component---src-pages-1-secure-index-jsx": () => import("./../../../src/pages/1secure/index.jsx" /* webpackChunkName: "component---src-pages-1-secure-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aiqon-default-index-jsx": () => import("./../../../src/pages/AiqonDefault/index.jsx" /* webpackChunkName: "component---src-pages-aiqon-default-index-jsx" */),
  "component---src-pages-aiqon-manage-index-jsx": () => import("./../../../src/pages/aiqon-manage/index.jsx" /* webpackChunkName: "component---src-pages-aiqon-manage-index-jsx" */),
  "component---src-pages-aiqon-secure-index-jsx": () => import("./../../../src/pages/aiqon-secure/index.jsx" /* webpackChunkName: "component---src-pages-aiqon-secure-index-jsx" */),
  "component---src-pages-ajuda-index-jsx": () => import("./../../../src/pages/ajuda/index.jsx" /* webpackChunkName: "component---src-pages-ajuda-index-jsx" */),
  "component---src-pages-ameacas-internas-index-jsx": () => import("./../../../src/pages/ameacas-internas/index.jsx" /* webpackChunkName: "component---src-pages-ameacas-internas-index-jsx" */),
  "component---src-pages-auditoria-de-alteracoes-index-jsx": () => import("./../../../src/pages/auditoria-de-alteracoes/index.jsx" /* webpackChunkName: "component---src-pages-auditoria-de-alteracoes-index-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-change-tracker-index-jsx": () => import("./../../../src/pages/change-tracker/index.jsx" /* webpackChunkName: "component---src-pages-change-tracker-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cortex-index-jsx": () => import("./../../../src/pages/cortex/index.jsx" /* webpackChunkName: "component---src-pages-cortex-index-jsx" */),
  "component---src-pages-criptografia-de-email-index-jsx": () => import("./../../../src/pages/criptografia-de-email/index.jsx" /* webpackChunkName: "component---src-pages-criptografia-de-email-index-jsx" */),
  "component---src-pages-default-index-jsx": () => import("./../../../src/pages/default/index.jsx" /* webpackChunkName: "component---src-pages-default-index-jsx" */),
  "component---src-pages-denuncia-index-jsx": () => import("./../../../src/pages/denuncia/index.jsx" /* webpackChunkName: "component---src-pages-denuncia-index-jsx" */),
  "component---src-pages-distribuicao-de-software-index-jsx": () => import("./../../../src/pages/distribuicao-de-software/index.jsx" /* webpackChunkName: "component---src-pages-distribuicao-de-software-index-jsx" */),
  "component---src-pages-dsar-index-jsx": () => import("./../../../src/pages/dsar/index.jsx" /* webpackChunkName: "component---src-pages-dsar-index-jsx" */),
  "component---src-pages-edr-index-jsx": () => import("./../../../src/pages/edr/index.jsx" /* webpackChunkName: "component---src-pages-edr-index-jsx" */),
  "component---src-pages-endpoint-protector-index-jsx": () => import("./../../../src/pages/endpoint-protector/index.jsx" /* webpackChunkName: "component---src-pages-endpoint-protector-index-jsx" */),
  "component---src-pages-enterprise-auditor-index-jsx": () => import("./../../../src/pages/enterprise-auditor/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-auditor-index-jsx" */),
  "component---src-pages-governanca-de-dados-index-jsx": () => import("./../../../src/pages/governanca-de-dados/index.jsx" /* webpackChunkName: "component---src-pages-governanca-de-dados-index-jsx" */),
  "component---src-pages-group-id-index-jsx": () => import("./../../../src/pages/group-id/index.jsx" /* webpackChunkName: "component---src-pages-group-id-index-jsx" */),
  "component---src-pages-group-id-password-index-jsx": () => import("./../../../src/pages/group-id-password/index.jsx" /* webpackChunkName: "component---src-pages-group-id-password-index-jsx" */),
  "component---src-pages-hardware-software-index-jsx": () => import("./../../../src/pages/hardware-software/index.jsx" /* webpackChunkName: "component---src-pages-hardware-software-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mitigacao-de-risco-index-jsx": () => import("./../../../src/pages/mitigacao-de-risco/index.jsx" /* webpackChunkName: "component---src-pages-mitigacao-de-risco-index-jsx" */),
  "component---src-pages-ndc-index-jsx": () => import("./../../../src/pages/ndc/index.jsx" /* webpackChunkName: "component---src-pages-ndc-index-jsx" */),
  "component---src-pages-netwrix-index-jsx": () => import("./../../../src/pages/netwrix/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-index-jsx" */),
  "component---src-pages-netwrix-products-index-jsx": () => import("./../../../src/pages/netwrix-products/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-products-index-jsx" */),
  "component---src-pages-netwrix-recovery-index-jsx": () => import("./../../../src/pages/netwrix-recovery/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-recovery-index-jsx" */),
  "component---src-pages-obrigado-jsx": () => import("./../../../src/pages/obrigado.jsx" /* webpackChunkName: "component---src-pages-obrigado-jsx" */),
  "component---src-pages-otimizacao-de-armazenamento-index-jsx": () => import("./../../../src/pages/otimizacao-de-armazenamento/index.jsx" /* webpackChunkName: "component---src-pages-otimizacao-de-armazenamento-index-jsx" */),
  "component---src-pages-parcerias-index-jsx": () => import("./../../../src/pages/parcerias/index.jsx" /* webpackChunkName: "component---src-pages-parcerias-index-jsx" */),
  "component---src-pages-password-policy-enforcer-index-jsx": () => import("./../../../src/pages/password-policy-enforcer/index.jsx" /* webpackChunkName: "component---src-pages-password-policy-enforcer-index-jsx" */),
  "component---src-pages-password-secure-index-jsx": () => import("./../../../src/pages/password-secure/index.jsx" /* webpackChunkName: "component---src-pages-password-secure-index-jsx" */),
  "component---src-pages-patch-manager-index-jsx": () => import("./../../../src/pages/patch-manager/index.jsx" /* webpackChunkName: "component---src-pages-patch-manager-index-jsx" */),
  "component---src-pages-pingcastle-index-jsx": () => import("./../../../src/pages/pingcastle/index.jsx" /* webpackChunkName: "component---src-pages-pingcastle-index-jsx" */),
  "component---src-pages-podcast-index-jsx": () => import("./../../../src/pages/podcast/index.jsx" /* webpackChunkName: "component---src-pages-podcast-index-jsx" */),
  "component---src-pages-policypak-index-jsx": () => import("./../../../src/pages/policypak/index.jsx" /* webpackChunkName: "component---src-pages-policypak-index-jsx" */),
  "component---src-pages-privilege-secure-index-jsx": () => import("./../../../src/pages/privilege-secure/index.jsx" /* webpackChunkName: "component---src-pages-privilege-secure-index-jsx" */),
  "component---src-pages-remediation-index-jsx": () => import("./../../../src/pages/remediation/index.jsx" /* webpackChunkName: "component---src-pages-remediation-index-jsx" */),
  "component---src-pages-rms-index-jsx": () => import("./../../../src/pages/rms/index.jsx" /* webpackChunkName: "component---src-pages-rms-index-jsx" */),
  "component---src-pages-scan-de-vulnerabilidades-index-jsx": () => import("./../../../src/pages/scan-de-vulnerabilidades/index.jsx" /* webpackChunkName: "component---src-pages-scan-de-vulnerabilidades-index-jsx" */),
  "component---src-pages-strongpoint-index-jsx": () => import("./../../../src/pages/strongpoint/index.jsx" /* webpackChunkName: "component---src-pages-strongpoint-index-jsx" */),
  "component---src-pages-syxsense-index-jsx": () => import("./../../../src/pages/syxsense/index.jsx" /* webpackChunkName: "component---src-pages-syxsense-index-jsx" */),
  "component---src-pages-threat-manager-index-jsx": () => import("./../../../src/pages/threat-manager/index.jsx" /* webpackChunkName: "component---src-pages-threat-manager-index-jsx" */),
  "component---src-pages-threat-prevention-index-jsx": () => import("./../../../src/pages/threat-prevention/index.jsx" /* webpackChunkName: "component---src-pages-threat-prevention-index-jsx" */),
  "component---src-pages-titular-de-dados-index-jsx": () => import("./../../../src/pages/titular-de-dados/index.jsx" /* webpackChunkName: "component---src-pages-titular-de-dados-index-jsx" */),
  "component---src-pages-usercube-index-jsx": () => import("./../../../src/pages/usercube/index.jsx" /* webpackChunkName: "component---src-pages-usercube-index-jsx" */)
}

